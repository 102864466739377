import axios from "axios";
import React from "react";

const baseURL =
	"https://www.e-solat.gov.my/index.php?r=esolatApi/TakwimSolat&period=today&zone=SGR01";

export default function Prayer() {
	const [post, setPost] = React.useState(null);
	const [data, setData] = React.useState([]);
	React.useEffect(() => {
		axios.get(baseURL).then((response) => {
			setPost(response.data);
			setData(response.data.prayerTime);
		});
	}, []);

	if (!post) return null;

	return (
		<div>
			{data.map((a, index) => (
				<>
					<div>Hijrah Date : {a.hijri}</div>
					<div>Date : {a.date}</div>
					<div>Day : {a.day}</div>
					<div>Imsak : {a.imsak}</div>
					<div>Subuh : {a.fajr}</div>
					<div>Syuruk : {a.syuruk}</div>
					<div>Zohor : {a.dhuhr}</div>
					<div>Asar : {a.asr}</div>
					<div>Maghrib : {a.maghrib}</div>
					<div>Isyak : {a.isha}</div>
				</>
			))}
		</div>
	);
}
