const header = {
	// all the properties are optional - can be left empty or deleted
	homepage: "https://www.baidelaire.com",
	title: "B41.",
};

const about = {
	// all the properties are optional - can be left empty or deleted
	name: "Baihaqi Hakim",
	role: ".... Software Engineer",
	description:
		"Currently working at Kossan, I am a skilled full-stack engineer with a passion for transforming ideas into robust software solutions. I continuously seek to advance my expertise through hands-on projects and side initiatives that enhance my skill set.   .   Based in Shah Alam , Selangor .  ",
	// resume: "https://baidelaire.github.io/online-cv/",
	// cv: "https://baidelaire.com/bai-cv.pdf",
	blog: "https://blog.baidelaire.com",
	social: {
		linkedin: "https://linkedin.com/in/baihaqi-hakim-98387012a/",
		// github2: "https://github.com/Baihakim",
		github: "https://github.com/baidelaire",
		// youtube:
		// 	"https://www.youtube.com/channel/UCvPPtIJUKLExxrDeYw4jwNw/playlists",
	},
};

const freelance = [
	// projects can be added an removed
	// if there are no projects, Projects section won't show up
	{
		name: "MyDOSH",
		description:
			"System and web apps developed for Health Screening Program Related to Occupational Diseases to Above 100,000 Workers in National Critical Sectors. -- Internal project under UITM Hospital Puncak Alam and DOSH Malaysia --",
		stack: [
			"ReactJS",
			"NodeJS",
			"ExpressJS",
			"Material UI",
			"Tailwind CSS",
			"Google Firebase",
			"Mysql",
			"RabbitMq(proposed)",
			"REST API",
			"Linux",
			"Nginx",
			"Jest(proposed)",
		],
		sourceCode: "https://github.com/baidelaire",
		livePreview: "https://blog.baidelaire.com/mydosh/",
	},
	{
		name: "Puzzlenomic v1",
		description:
			"Economics crossword puzzle apps associated with UITM Dengkil .This project has been awarded gold medal for the previous International Innovation Competition (INNOCOM III) . ",
		stack: [
			"React",
			"React Native",
			"Mobile",
			"Mysql",
			"AWS",
			"GOLANG",
			"REST API",
		],
		sourceCode: "https://github.com/baidelaire",
		livePreview: "https://blog.baidelaire.com/puzzlenomic/",
	},
];

const works = [
	// projects can be added an removed
	// if there are no projects, Projects section won't show up
	{
		name: "kuasa.io",
		description:
			"Upgrade the functionality from the old funnel system (saleskip.com) to more powerful funnel system with custom drag and drop CRM, drag n drop page builder , Whatsapp , email , sms broadcasting and automation , omnichannel(Whatsapp + crm live chat) and more . -- Internal project under kuasa.io --",
		stack: ["PHP", "Javascript", "HTML/CSS", "AJAX", "REST API"],
		sourceCode: "https://github.com/baidelaire",
		livePreview: "https://kuasa.io/",
	},
	{
		name: "addar Beauty App",
		description:
			"Android App , mobile shopping and cart, integrated with Ninjavan for delivery , and billplz for payment gateway . Rubysoft custom CMS for dynamic content management -- Internal project under Rubysoft --",
		stack: ["REST API", "PHP", "React Native", "Mobile"],
		sourceCode: "https://github.com/baidelaire",
		livePreview: "https://addarapp.com/",
	},
];

const projects = [
	// projects can be added an removed
	// if there are no projects, Projects section won't show up
	{
		name: "Inshorts Clone",
		description:
			"News App , modified with Malaysia top headline news. Cons: Due to free version account , the api request capped by provider.",
		stack: ["Newsapi", "Expo", "React Native", "Android"],
		sourceCode: "https://github.com/baidelaire/newsapp",
		livePreview: "https://github.com",
	},
	{
		name: "baidelaire",
		description:
			"My personal website as medium to showcase my skills and previous works .",
		stack: ["React", "CSS", "Github gist"],
		sourceCode: "https://github.com/baidelaire",
		livePreview: "https://baidelaire.com",
	},
	{
		name: "Baidelaire Go",
		description:
			"Travel advisor application that display the best rated restaurant and dining around us according to trip advisor rating  .",
		stack: ["Google API", "Material UI", "React"],
		sourceCode: "https://github.com/baidelaire/travel",
		livePreview: "https://baidelaire-trip.netlify.app",
	},
	{
		name: "Bai-Do",
		description:
			"(IN PROGRESS) Special to do app with realtime synchronization ( create , read , update , delete ) . Manage your list with automation ( a notification will be sent as a reminder if the list not checked yet) . ",
		stack: [
			"Google Firebase",
			"Android",
			"React Native",
			"Node.js",
			"Expo Notifications",
		],
		sourceCode: "https://github.com",
		livePreview: "https://github.com",
	},
	{
		name: "Planify",
		description:
			"Custom drag n drop task management (like trello) using java as backend . This one is a group project for my part time degree studies as a part of subject assesments . ",
		stack: [
			"Java",
			"Servlet",
			"JSP",
			"javascript",
			"HTML / CSS",
			"AJAX",
			"JDBC",
		],
		sourceCode: "https://github.com",
		livePreview: "https://project.baidelaire.com/planify",
	},
];

const skills = [
	// skills can be added or removed
	// if there are no skills, Skills section won't show up
	"HTML",
	"CSS",
	"CI/CD",
	"AJAX",
	"REST API",
	"NodeJS",
	"Express",
	"Java",
	"JavaScript",
	"PHP",
	"React",
	"Laravel",
	"Golang",
	"SQL",
	"LINUX",
	"Digital Ocean",
	"React Native",
];

const contact = {
	// email is optional - if left empty Contact section won't show up
	email: "work@baidelaire.com",
};

export { header, about, freelance, projects, skills, contact, works };
