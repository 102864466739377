import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles({
	root: {
		maxWidth: 645,
		background: "rgba(0,0,0,0.5)",
		margin: "20px",
	},
	media: {
		height: 440,
	},
	title: {
		fontFamily: "Nunito",
		fontWeight: "bold",
		fontSize: "2rem",
		color: "#fff",
	},
	desc: {
		fontFamily: "Nunito",
		fontSize: "1.1rem",
		color: "#ddd",
	},
});

export default function ImageCard({ place, checked }) {
	const classes = useStyles();

	const [quote, setQuote] = useState("");
	const [author, setAuthor] = useState("");
	const [start, setStart] = useState(0);

	const MINUTE_MS = 15000;

	useEffect(() => {
		if (start === 1) {
			const interval = setInterval(() => {
				getQuote();
			}, MINUTE_MS);
			return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
		} else {
			getQuote();
		}
	}, [start]);

	const getQuote = () => {
		let url = `https://gist.githubusercontent.com/baidelaire/ce68e4edaaba6ae8c4031917b0cbadb0/raw/2c2e812d64c1e3c98f30f5bef843607ad7266105/quotes.json`;
		fetch(url)
			.then((res) => res.json())
			.then((data) => {
				let dataQuotes = data.quotes;
				let randomNum = Math.floor(Math.random() * dataQuotes.length);
				let randomQuote = dataQuotes[randomNum];

				setQuote(randomQuote.quote);
				setAuthor(randomQuote.author);
				setStart(1);
			});
	};

	return (
		<Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
			<Card className={classes.root}>
				<CardMedia
					className={classes.media}
					image={place.imageUrl}
					title="Contemplative Reptile"
				/>
				<CardContent>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						className={classes.desc}
					>
						"{quote}"
					</Typography>
					<Typography
						gutterBottom
						variant="h5"
						component="h1"
						className={classes.title}
					>
						{author}
					</Typography>
				</CardContent>
			</Card>
		</Collapse>
	);
}
