import React from "react";
import "./Footer.css";

const Footer = () => (
	<footer className="footer">
		<a href="https://www.baidelaire.com" className="link footer__link">
			Created By Baihaqi Hakim
		</a>
	</footer>
);

export default Footer;
