import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AboutPage from "./About";
import UpdatePage from "../aboutcomponents/Update";
import App from "../App";
import Prayer from "../Prayer";

class Routes extends Component {
	render() {
		return (
			<div>
				<Route exact path="/" component={App} />
				<Route exact path="/prayer" component={Prayer} />
				<Route exact path="/about" component={AboutPage} about={1} />
				{/* <Route exact path="/update" component={UpdatePage} update={1} /> */}
			</div>
		);
	}
}

export default Routes;
