import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, IconButton, Toolbar, Collapse } from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link as Scroll } from "react-scroll";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
		fontFamily: "Nunito",
	},
	appbar: {
		background: "none",
	},
	appbarWrapper: {
		width: "80%",
		margin: "0 auto",
	},
	appbarTitle: {
		flexGrow: "1",
	},
	icon: {
		color: "#fff",
		fontSize: "2rem",
	},
	colorText: {
		color: "#5AFF3D",
	},
	container: {
		textAlign: "center",
	},
	title: {
		color: "#fff",
		fontSize: "4.5rem",
	},
	titlemobile: {
		color: "#fff",
		fontSize: "3.5rem",
	},
	goDown: {
		color: "#5AFF3D",
		fontSize: "4rem",
	},
	iframeStyle: {
		borderRadius: "12px",
	},
}));
export default function Header() {
	const classes = useStyles();
	const [checked, setChecked] = useState(false);
	useEffect(() => {
		setChecked(true);
	}, []);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (pg) => {
		setAnchorEl(null);
		if (pg == 3) {
			window.open("http://baidelaire-trip.netlify.app", "_blank");
		} else if (pg == 4) {
			window.open("http://blog.baidelaire.com", "_blank");
		}
	};

	return (
		<div className={classes.root} id="header">
			<AppBar className={classes.appbar} elevation={0}>
				<Toolbar className={classes.appbarWrapper}>
					<h1 className={classes.appbarTitle}>
						Bai<span className={classes.colorText}>delaire.</span>
					</h1>
					<IconButton
						ria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClick}
					>
						<SortIcon className={classes.icon} />
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={handleClose}>Home</MenuItem>
						<MenuItem component={Link} to="/about">
							About
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleClose(3);
							}}
						>
							Travel
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleClose(4);
							}}
						>
							Blog
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>

			<Collapse
				in={checked}
				{...(checked ? { timeout: 1000 } : {})}
				collapsedHeight={50}
			>
				<div className={classes.container}>
					<h1
						className={
							window.screen.width <= 400
								? classes.titlemobile
								: classes.title
						}
					>
						Welcome to <br />
						<span className={classes.colorText}>bai</span>delaire.
					</h1>
					{/* <iframe
						className={classes.iframeStyle}
						src="https://open.spotify.com/embed/playlist/7z13jaPryFcCJN06emU3vm?utm_source=generator"
						width="100%"
						height="80"
						frameBorder="0"
						allowfullscreen=""
						allowtransparency="true"
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
					/> */}
					<Scroll to="place-to-visit" smooth={true}>
						<IconButton>
							<ExpandMoreIcon className={classes.goDown} />
						</IconButton>
					</Scroll>
				</div>
			</Collapse>
		</div>
	);
}
