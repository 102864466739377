import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YoutubeIcon from "@material-ui/icons/YouTube";
import { about } from "../../portfolio";
import "./About.css";

const About = () => {
	const { name, role, description, resume, cv, social, blog } = about;

	return (
		<div className="about center">
			{name && (
				<h1>
					Hi, I am <span className="about__name">{name}.</span>
				</h1>
			)}

			{role && <h2 className="about__role">A {role}.</h2>}
			<p className="about__desc">{description && description}</p>

			<div className="about__contact center">
				{resume && (
					<a href={resume}>
						<span type="button" className="btn btn--outline">
							Resume
						</span>
					</a>
				)}

				{cv && (
					<a href={cv}>
						<span type="button" className="btn btn--outline">
							CV
						</span>
					</a>
				)}
				{blog && (
					<a href={blog}>
						<span type="button" className="btn btn--outline">
							Blog
						</span>
					</a>
				)}

				{social && (
					<>
						{social.github && (
							<a
								href={social.github}
								aria-label="github"
								className="link link--icon"
							>
								<GitHubIcon />
							</a>
						)}

						{social.github2 && (
							<a
								href={social.github2}
								aria-label="github"
								className="link link--icon"
							>
								<GitHubIcon />
							</a>
						)}

						{social.youtube && (
							<a
								href={social.youtube}
								aria-label="youtube"
								className="link link--icon"
							>
								<YoutubeIcon />
							</a>
						)}

						{social.linkedin && (
							<a
								href={social.linkedin}
								aria-label="linkedin"
								className="link link--icon"
							>
								<LinkedInIcon />
							</a>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default About;
